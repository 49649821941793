import { AppLayoutsEnum } from "@/app/layouts";
import { authRoutes } from "@/pages/auth";

interface RouteInterface {
	name: string;
	title?: string;
}

export const RoutesDataMap: Record<string, RouteInterface> = {
	home: {
		name: "home",
		title: "Главная",
	},
	policy: {
		name: "policy",
		title: "Политика конфиденциальности",
	},
	technical: {
		name: "technical",
		title: "Техническая страница",
	},
	page404: {
		name: "page404",
		title: "Страница 404 ошибки",
	},
	test: {
		name: "test",
		title: "Страница test",
	},
};

export const routes = [
	{
		path: "/",
		redirect: { name: RoutesDataMap.home.name },
		meta: {
			title: RoutesDataMap.home.title,
		},
		children: [
			{
				path: "/",
				name: RoutesDataMap.home.name,
				component: () => import("@/pages/test"),
				meta: {
					layout: AppLayoutsEnum.default,
					title: "Тест",
				},
			},
			{
				path: "/products",
				name: "products",
				component: () => import("@/pages/fast/products"),
				meta: {
					layout: AppLayoutsEnum.default,
					title: "Готовый товар",
				},
			},
			{
				path: "/packaging-list",
				name: "packaging-list",
				component: () => import("@/pages/fast/packaging-list"),
				meta: {
					layout: AppLayoutsEnum.default,
					title: "Упаковки",
				},
			},
			{
				path: "/tenders",
				name: "tenders",
				component: () => import("@/pages/fast/tenders"),
				meta: {
					layout: AppLayoutsEnum.default,
					title: "Тендеры",
				},
			},
			{
				path: "/orders",
				name: "orders",
				component: () => import("@/pages/fast/orders"),
				meta: {
					layout: AppLayoutsEnum.default,
					title: "Заказы",
				},
			},

			{
				path: "/packaging/box",
				name: "packaging-box",
				component: () => import("@/pages/fast/packaging"),
				meta: {
					layout: AppLayoutsEnum.default,
					title: "Упаковка",
				},
			},
			{
				path: "/data-table",
				name: "datatable",
				component: () => import("@/pages/testDataTable"),
				meta: {
					layout: AppLayoutsEnum.default,
				},
			},
			{
				path: "/document",
				name: "document",
				component: () => import("@/pages/fast/document"),
				meta: {
					layout: AppLayoutsEnum.default,
					title: "ТХК_84464",
				},
			},
			{
				path: "/product",
				name: "product",
				component: () => import("@/pages/fast/product"),
				meta: {
					layout: AppLayoutsEnum.default,
					title: "Маска для лица очищающая",
				},
			},
			{
				path: "/test",
				name: RoutesDataMap.test.name,
				component: () => import("@/pages/test"),
				meta: {
					layout: AppLayoutsEnum.default,
					title: RoutesDataMap.test.title,
				},
			},
			{
				path: "/policy",
				name: RoutesDataMap.policy.name,
				component: () => import("@/pages/policy"),
				meta: {
					layout: AppLayoutsEnum.default,
					title: RoutesDataMap.policy.title,
				},
			},
			{
				path: "/technical",
				name: RoutesDataMap.technical.name,
				component: () => import("@/pages/technical"),
				meta: {
					layout: AppLayoutsEnum.tech,
				},
				props: true,
			},
			{
				path: "/components",
				name: "components",
				component: () => import("@/pages/support/components"),
			},
			{
				path: "/:pathMatch(.*)*",
				name: RoutesDataMap.page404.name,
				component: () => import("@/pages/page404"),
				meta: {
					layout: AppLayoutsEnum.page404,
				},
			},
		],
	},
	{
		path: "/catalog",
		name: "catalog",
		component: () => import("@/pages/fast/catalog"),
		meta: {
			layout: AppLayoutsEnum.default,
			title: "Каталог",
		},
		children: [
			{
				path: "/catalog/packaging-solutions",
				name: "catalog-packaging-solutions",
				component: () => import("@/pages/fast/catalog/packaging-solutions"),
				meta: {
					layout: AppLayoutsEnum.default,
					title: "Упаковочные решения",
				},
			},
			{
				path: "/catalog/packaging",
				name: "catalog-packaging",
				component: () => import("@/pages/fast/catalog/packaging"),
				meta: {
					layout: AppLayoutsEnum.default,
					title: "Каталог упаковок",
				},
			},
			{
				path: ":category",
				name: "catalog category",
				component: () => import("@/pages/fast/catalog/category"),
				meta: {
					layout: AppLayoutsEnum.default,
				},

				children: [],
			},

			{
				path: ":category/:boxType",
				name: "box page",
				component: () => import("@/pages/fast/catalog/box"),
				meta: {
					layout: AppLayoutsEnum.default,
				},
			},

			{
				path: ":category/:boxType/:boxId",
				name: "item page",
				component: () => import("@/pages/fast/catalog/item"),
				meta: {
					layout: AppLayoutsEnum.default,
				},
			},
		],
	},

	...authRoutes,
];
