import type {App} from "vue";
import {ClickOutside} from "element-plus";
import {vMaska} from "maska";


export default {
	install (app: App) {
		app.directive('click-outside', ClickOutside)
		app.directive('mask', vMaska)
	}
}
