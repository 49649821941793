import type { RouteLocationNormalized } from "vue-router";

export function generateDynamicTitle(route: RouteLocationNormalized) {
	if (route.meta.data && typeof route.meta.data === "object") {
		const param = Object.values(route.params).at(-1);

		//@ts-expect-error sadge
		const title = route.meta.data[param];

		if (title) route.meta.title = title;
	}
}
