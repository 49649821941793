import { ref } from "vue";
import type { Ref } from "vue";
import { defineStore } from "pinia";

interface ErrorInfo {
	errorTitle: string,
	errorDesc: string
}

export const useErrorStore = defineStore( 'counter', () => {
	const errorInfo: Ref<ErrorInfo> = ref( {
		errorTitle: '',
		errorDesc: ''
	} )

	function setErrorInfo( newErrorInfo: ErrorInfo ) {
		if ( newErrorInfo.errorTitle ) errorInfo.value.errorTitle = newErrorInfo.errorTitle
		if ( newErrorInfo.errorDesc ) errorInfo.value.errorDesc = newErrorInfo.errorDesc
	}

	return { errorInfo, setErrorInfo }
} )

export type {
	ErrorInfo
}
