import { duplicator } from "../lib/functions/duplicator";
import {
	EPackageSlugs,
	type IMaterial,
	type IPackage,
} from "../types/package.types";

export const packagesList: IPackage[] = [
	{
		name: "Картонная гофроупаковка",
		slug: EPackageSlugs.CARTON,
		children: [
			{
				name: "4-х клапанные гофрокороба",
				images: [...duplicator("/box/carton/carton-1_1.png", 5)],
			},
			{
				name: "Шоубокс",
				images: [...duplicator("/box/carton/carton-1_2.png", 5)],
			},
			{
				name: "Гофролоток",
				images: [...duplicator("/box/carton/carton-1_3.png", 5)],
			},
			{
				name: "Коробка крышка-дно",
				images: [...duplicator("/box/carton/carton-1_4.png", 5)],
			},
			{
				name: "Коробка с ушками",
				images: [...duplicator("/box/carton/carton-1_5.png", 5)],
			},
			{
				name: "Коробка с крышкой",
				images: [...duplicator("/box/carton/carton-1_6.png", 5)],
			},
			{
				name: "Коробка для пиццы",
				images: [...duplicator("/box/carton/carton-1_7.png", 5)],
			},
			{
				name: "Чемодан",
				images: [...duplicator("/box/carton/carton-1_8.png", 5)],
			},
			{
				name: "Коробка с откидной крышкой",
				images: [...duplicator("/box/carton/carton-1_9.png", 5)],
			},
			{
				name: "Октабин",
				images: [...duplicator("/box/carton/carton-1_10.png", 5)],
			},
		],
	},

	{
		name: "Картонная полиграфическая",
		slug: EPackageSlugs.POLY,
		children: [
			{
				name: "Коробка с крышкой самосборная",
				images: [
					"/box/polygraphy/poly-1_1.png",
					...duplicator("/box/polygraphy/poly-2_1.png", 5),
				],
			},
			{
				name: "Шоубокс",
				images: [
					"/box/polygraphy/poly-1_2.png",
					...duplicator("/box/polygraphy/poly-2_2.png", 5),
				],

				children: [
					{
						name: "FEFCO 0201",
						images: [...duplicator("/box/showbox_1.png")],
						subtitle: "С перфорацией",
					},
					{
						name: "FEFCO 0215",
						images: [...duplicator("/box/showbox_1.png")],
						subtitle: "С перфорацией",
					},
					{
						name: "FEFCO 0216",
						images: [...duplicator("/box/showbox_1.png")],
						subtitle: "С перфорацией",
					},
					{
						name: "FEFCO 0711",
						images: [...duplicator("/box/showbox_1.png")],
						subtitle: "С перфорацией",
					},
					{
						name: "FEFCO 0713",
						images: [...duplicator("/box/showbox_1.png")],
						subtitle: "С перфорацией",
					},
				],
			},
			{
				name: "Коробка с крышкой",
				images: [
					"/box/polygraphy/poly-1_3.png",
					...duplicator("/box/polygraphy/poly-2_3.png", 5),
				],
			},
			{
				name: "Коробка крышка-дно",
				images: [
					"/box/polygraphy/poly-1_4.png",
					...duplicator("/box/polygraphy/poly-2_4.png", 5),
				],
			},
			{
				name: "Коробка домик",
				images: [
					"/box/polygraphy/poly-1_5.png",
					...duplicator("/box/polygraphy/poly-2_5.png", 5),
				],
			},
			{
				name: "Подарочная коробка",
				images: [
					"/box/polygraphy/poly-1_6.png",
					...duplicator("/box/polygraphy/poly-2_6.png", 5),
				],
			},
			{
				name: "Круглая коробка",
				images: [
					"/box/polygraphy/poly-1_7.png",
					...duplicator("/box/polygraphy/poly-2_7.png", 5),
				],
			},

			{
				name: "Коробка чемодан",
				images: [
					"/box/polygraphy/poly-1_8.png",
					...duplicator("/box/polygraphy/poly-2_8.png", 5),
				],
			},
			{
				name: "Тубус",
				images: [
					"/box/polygraphy/poly-1_9.png",
					...duplicator("/box/polygraphy/poly-2_9.png", 5),
				],
			},
		],
	},

	{
		name: "Пластиковая упаковка",
		slug: EPackageSlugs.PLASTIC,
		children: [
			{
				name: "Банка",
				images: [...duplicator("/box/plastic/plastic_1.png", 5)],
				children: [
					{
						name: "Банка",
						images: [...duplicator("/box/jar_1.png")],
					},
					{
						name: "Банка",
						images: [...duplicator("/box/jar_1.png")],
					},
					{
						name: "Банка",
						images: [...duplicator("/box/jar_1.png")],
					},
					{
						name: "Банка",
						images: [...duplicator("/box/jar_1.png")],
					},
				],
			},
			{
				name: "Флакон",
				images: [...duplicator("/box/plastic/plastic_2.png", 5)],
			},
			{
				name: "Бутылка",
				images: [...duplicator("/box/plastic/plastic_3.png", 5)],
			},
			{
				name: "Ролл-оны",
				images: [...duplicator("/box/plastic/plastic_4.png", 5)],
			},
			{
				name: "Туба",
				images: [...duplicator("/box/plastic/plastic_5.png", 5)],
			},

			{
				name: "Контейнеры",
				images: [...duplicator("/box/plastic/plastic_6.png", 5)],
			},
			{
				name: "Стакан под запайку",
				images: [...duplicator("/box/plastic/plastic_7.png", 5)],
			},

			{
				name: "Блистеры",
				images: [...duplicator("/box/plastic/plastic_11.png", 5)],
			},
			{
				name: "Палетки",
				images: [...duplicator("/box/plastic/plastic_8.png", 5)],
			},
			{
				name: "Стики",
				images: [...duplicator("/box/plastic/plastic_9.png", 5)],
			},
			{
				name: "Кушон",
				images: [...duplicator("/box/plastic/plastic_10.png", 5)],
			},
		],
	},
	{
		name: "Стеклянная упаковка",
		slug: EPackageSlugs.GLASS,
		children: [
			{ name: "Банки", images: [...duplicator("/box/placeholder-box.png", 5)] },
			{
				name: "Бутылки",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{
				name: "Флаконы",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{ name: "Фляги", images: [...duplicator("/box/placeholder-box.png", 5)] },
			{
				name: "Ампулы",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
		],
	},

	{
		name: "Этикетки, бирки, стикеры",
		slug: EPackageSlugs.ETICETTE,
		children: [
			{
				name: "Этикетки",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{ name: "Бирки", images: [...duplicator("/box/placeholder-box.png", 5)] },
			{
				name: "Контроль вскрытия",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
		],
	},
	{
		name: "Упаковочные материалы",

		slug: EPackageSlugs.MATERIALS,
		children: [
			{
				name: "Лист гофрокатона",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{
				name: "Пакеты",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{ name: "Скотч", images: [...duplicator("/box/placeholder-box.png", 5)] },
			{
				name: "Стрейч",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{
				name: "Стриплента",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{ name: "Скобы", images: [...duplicator("/box/placeholder-box.png", 5)] },
			{
				name: "Воздушно-пупырчатая пленка",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{
				name: "Уголки",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
		],
	},
	{
		name: "POS-материалы",
		slug: EPackageSlugs.POS,
		children: [
			{
				name: "Воблеры",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{
				name: "Шелфтокеры",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{
				name: "Подвесные банеры",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{
				name: "Диспенсеры",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{
				name: "Шелорганайзеры",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{
				name: "Стрип-холдеры",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{
				name: "Некхенгеры",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
			{
				name: "Дисплей-витрины",
				images: [...duplicator("/box/placeholder-box.png", 5)],
			},
		],
	},
];

export const materials: IMaterial[] = [
	{
		children: [
			"2-х слойный гофрокартон",
			"3-х слойный гофрокартон",
			"5-х слойный гофрокартон",
			"7-и слойный гофрокартон",
			"Мелованный картон",
			"Мелованный целлюлозный картон",
			"Дизайнерская бумага",
		],
		categorySlug: EPackageSlugs.CARTON,
	},

	{
		children: [
			"Мелованный картон",
			"Мелованный целлюлозный картон",
			"Кашированный картон",
			"Дизайнерский картон",
			"СЛИМ-кашированный",
			"Переплетный картон",
		],
		categorySlug: EPackageSlugs.POLY,
	},
	{
		categorySlug: EPackageSlugs.PLASTIC,
		children: ["ПП", "ПЭ", "ПЭТ", "ПВХ"],
	},
];
