import type { RouteRecordRaw } from "vue-router";
import { AppLayoutsEnum } from "@/app/layouts";

const AuthIndex = () => import("./ui/AuthIndex.vue");
const AuthLogin = () => import("./ui/AuthLogin.vue");
const AuthRegister = () => import("./ui/AuthRegister.vue");
const AuthRestore = () => import("./ui/AuthRestore.vue");

export const authRoutes: RouteRecordRaw[] = [
	{
		path: "/auth",
		component: AuthIndex,
		meta: { layout: AppLayoutsEnum.auth },

		children: [
			{ path: "register", component: AuthRegister },
			{
				path: "",
				component: AuthLogin,
			},
			{
				path: "restore",
				component: AuthRestore,
			},
		],
	},
];
