import { buildProps, definePropType } from "@/shared/lib";

export const iconProps = buildProps({
	size: {
		type: definePropType<number | string>([Number, String]),
		default: 24
	},
	color: {
		type: String,
		default: "currentColor"
	}
})

export const iconEmits = {}
