<template>
	<suspense>
		<component :is="(route.meta.layoutComponent as {})">
			<slot />
		</component>
	</suspense>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";

const route = useRoute();
</script>

<style lang="scss" scoped></style>
