export interface IBox {
	name: string;
	images: string[];

	children?: (IBox & { subtitle?: string })[];
}

export interface IPackage {
	name: string;
	children: IBox[];
	slug: EPackageSlugs;
}

export type TPackagesList = IPackage[];

export enum EPackageSlugs {
	POLY = "POLY",
	CARTON = "CARTON",
	PLASTIC = "PLASTIC",
	GLASS = "GLASS",
	ETICETTE = "ETICETTE",
	MATERIALS = "MATERIALS",
	POS = "POS",
}

export interface IMaterial {
	children: string[];
	categorySlug: EPackageSlugs;
}
