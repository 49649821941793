import ElementPlus from "element-plus"
import type { App } from "vue"

export function useElementPlusPlugin( app: App ) {
	app.use( ElementPlus )
}

export default {
	install: ( app: App, options?: any ) => {
		useElementPlusPlugin( app )
	}
}
