import { createRouter, createWebHistory, stringifyQuery } from "vue-router";
import { routes } from "@/pages";

import { loadLayoutMiddleware } from "./middleware/loadLayout.middleware";
import { generateDynamicTitle } from "./middleware/generateDynamicTitle";

export const router = createRouter({
	history: createWebHistory(),
	stringifyQuery: (query) => stringifyQuery(query),
	routes,
});

router.beforeEach(loadLayoutMiddleware);

router.beforeEach(generateDynamicTitle);

export * from "./router.types";
