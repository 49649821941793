import type { ButtonEmits, ButtonProps } from "../model/defaults.ts";
import type { SetupContext } from "vue";
import { computed, ref, toRefs } from "vue";
import { isString } from "@vue/shared";
import classNames from "classnames";

export const useButton = (
	props: ButtonProps,
	emit: SetupContext<ButtonEmits>["emit"],
) => {
	const {
		tag,
		type,
		styleOption,
		option,
		size,
		disabled,
		icon,
		rightIcon,
		loading,
		loadingIcon,
	} = toRefs(props);
	const _ref = ref<HTMLButtonElement>();

	const handleClick = (evt: MouseEvent) => {
		emit("click", evt);
	};

	const _props = computed(() => {
		if (isString(props.tag) && props.tag === "button") {
			return {
				disabled: disabled.value || loading.value,
			};
		}

		return {};
	});

	const _classNames = computed(() => {
		const baseClassName = "c-button";
		const buttonType = type.value || "default";
		const buttonStyle = styleOption.value || "filled";
		const buttonOption = option.value || "primary";
		const buttonSize = size.value || "medium";

		return classNames({
			[`${baseClassName}`]: true,
			[`${baseClassName}--${buttonType}`]: true,
			[`${baseClassName}--${buttonStyle}`]: true,
			[`${baseClassName}--${buttonOption}`]: true,
			[`${baseClassName}--${buttonSize}`]: true,
			[`is-loading`]: loading.value,
		});
	});

	return {
		_ref,
		_props,
		_classNames,
		tag,
		icon,
		rightIcon,
		loading,
		loadingIcon,
		handleClick,
	};
};
