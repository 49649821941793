import type { ExtractPropTypes } from "vue";

export enum AppLayoutsEnum {
	default = "default",
	tech = "tech",
	page404 = "page404",
	error = "error",
	auth = "auth",
}

export const AppLayoutToFileMap: Record<AppLayoutsEnum, string> = {
	default: "app-layout-default.vue",
	tech: "app-layout-tech.vue",
	page404: "app-layout-page404.vue",
	error: "app-layout-error.vue",
	auth: "app-layout-auth.vue",
};

export const PROVIDE_INJECT_LAYOUT = "PROVIDE_INJECT_LAYOUT";

export const layoutProps = {
	title: {
		type: String,
	},
	description: {
		type: String,
	},
};

export const layoutEmits = {};

type LayoutProps = ExtractPropTypes<typeof layoutProps>;
type LayoutEmits = typeof layoutEmits;

export default layoutProps;

export type { LayoutProps, LayoutEmits };
