import type { ExtractPropTypes } from "vue";
import { readonly } from "vue";
import { buildProps, definePropType, iconPropType } from "@/shared/lib";
import { ILoading } from "@/shared/ui/icon-pack"

// Типы (они же семантики)
export const buttonTypes = [
	'default', // neutral
	'accent', // accent
	'success', // positive
	'warning', // notice
	'info', // informative
	'danger' // negative
] as const

export const buttonStyles = [
	'filled', // залитая
	'bordered', // обведенная
	'text', // текстовая
] as const

export const buttonOptions = [
	'primary',
	'secondary',
] as const

export const buttonSizes = [
	'large',
	'medium',
	'small',
	'extra-small',
	'extra-extra-small',
] as const

export const buttonProps = readonly(buildProps({
	type: {
		type: String,
		values: buttonTypes,
		default: 'default',
	},
	styleOption: {
		type: String,
		values: buttonStyles,
		default: 'bordered',
	},
	option: {
		type: String,
		values: buttonOptions,
		default: 'primary',
	},
	size: {
		type: String,
		values: buttonSizes,
		default: 'medium',
	},
	fullWidth: {
		type: Boolean,
		default: false
	},
	disabled: {
		type: Boolean,
		default: false
	},
	plain: {
		type: Boolean,
		default: false
	},
	tag: {
		type: definePropType<string>([String]),
		default: 'button',
	},
	icon: {
		type: iconPropType,
	},
	rightIcon: {
		type: iconPropType,
	},
	loading: {
		type: Boolean,
		default: false,
	},
	loadingIcon: {
		type: iconPropType,
		default: () => ILoading,
	}
} as const))

export const buttonEmits = {
	'click': (evt: MouseEvent) => evt instanceof MouseEvent,
}

export type ButtonProps = ExtractPropTypes<typeof buttonProps>
export type ButtonEmits = typeof buttonEmits

export type ButtonType = ButtonProps['type']
export type ButtonStyle = ButtonProps['styleOption']
export type ButtonOption = ButtonProps['option']
export type ButtonSize = ButtonProps['size']

export default buttonProps
