import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import type { TAnyFunction } from "@/shared/types/shared.types";

export interface IAdditionalButtonData {
	text: string;
	link: string;
	action: TAnyFunction;
}

export const usePageStore = defineStore("page", () => {
	const _title = ref<String>("");
	const _description = ref<String>("");
	const _classNames = ref<String>("");

	const title = computed<String>(() => _title.value);
	const description = computed<String>(() => _description.value);
	const classNames = computed<String>(() => _classNames.value);

	const additionalButtonData = ref<IAdditionalButtonData | null>(null);

	const setTitle = (str: String) => (_title.value = str);
	const setDescription = (str: String) => (_description.value = str);
	const setClassNames = (str: String) => (_classNames.value = str);

	const setAdditionalButtonData = (data: IAdditionalButtonData) =>
		(additionalButtonData.value = data);

	const clearAdditionalButtonData = () => (additionalButtonData.value = null);

	const reset = () => {
		_title.value = "";
		_description.value = "";
		_classNames.value = "";
	};

	const route = useRoute();

	watch(route, () => {
		reset();
	});

	return {
		title,
		description,
		classNames,
		setTitle,
		setDescription,
		setClassNames,
		setAdditionalButtonData,
		additionalButtonData,
		clearAdditionalButtonData,
	};
});
