import { createApp } from "vue";
import { router, store } from "@/app/providers";

import App from "./index.vue";

import "./index.scss";

import "swiper/css";
import "swiper/css/pagination";

import elementPlusPlugin from "@/app/plugins/element/element-plus";
import globalDirectives from "@/app/plugins/directives/globalInstall";
import autoAnimate from "./plugins/auto-animate";

export const app = createApp(App);

app.use(router);
app.use(store);

app.use(elementPlusPlugin);
app.use(globalDirectives);
app.use(autoAnimate);
